import { useContext, useState, useEffect } from "react";
import { AppContext } from "../App";
import { useParams, useNavigate } from "react-router-dom";
import { waitConfirmTrx } from "../utils";
import { JettonRoot } from '@dedust/sdk'
import {
  useTonAddress,
  useTonConnectUI,
} from "@tonconnect/ui-react";

import * as C from "../config"
import axios from "axios"

import { beginCell, Address, toNano, fromNano } from "@ton/ton";
import { initializeClient, getJettonWalletAddress, roundDecimal } from "../utils"

const ADMIN_PUBKEY = "UQC7OYIkxIqWrezgeqw1YA0R9OfrrFG1kuDF_wuUqd14Xceb";

function Misc() {
  const navigate = useNavigate();
  const walletAddress = useTonAddress();
  const [tonConnectUI] = useTonConnectUI();

  const { userInfo, setUserInfo, setLoadingPrompt, setOpenLoading } = useContext(AppContext);
  const [type, setType] = useState("0");
  const [stakingAmount, setStakingAmount] = useState(1);
  const [buyAmount, setBuyAmount] = useState(1);
  const [claimAmount, setClaimAmount] = useState(1);
  const { state } = useParams(); // Extracting userId from URL
  const [claimDisabled, setClaimDisabled] = useState(0);
  const [leftTokens, setLeftTokens] = useState(0);
  const [users, setUsers] = useState(0);
  const [withdrawDisabled, setWithdrawDisabled] = useState(true);


  // useEffect(() => {
  //   if (walletAddress == ADMIN_PUBKEY)
  //     setWithdrawDisabled(false);
  //   else 
  //     setWithdrawDisabled(true);
  // }, [walletAddress]);

  useEffect(() => {
    setType(state);
    updateWorldState();
  }, [state]);

  useEffect(() => {
    if (userInfo.buy_balance > 0)
      setClaimDisabled(1);
    else
      setClaimDisabled(0);
  }, [userInfo]);

  const updateWorldState = async () => {
    const client = await initializeClient("mainnet");
    const jettonRoot = client.open(
      JettonRoot.createFromAddress(Address.parse(C.JETTON_MASTER_ADDRESS))
    )
    const jettonWallet = client.open(
      await jettonRoot.getWallet(Address.parse(C.CONTRACT_ADDR))
    )
    const jettonBalance = await jettonWallet.getBalance();
    console.log("jettonBalance>>>>>>>>>>>>>>>>", roundDecimal(fromNano(jettonBalance), 10))
    setLeftTokens(roundDecimal(fromNano(jettonBalance), 10));

    const token = localStorage.getItem("authorization");
    const { data } = await axios.get(`${C.API_BASE_URL}/user/user-count`, {
      headers: {
        Authorization: `bearer ${token}`
      }
    });
    console.log("user count::::::::::::", data.data)
    setUsers(roundDecimal(data.data, 10));
  }

  const airdropHandler = async () => {
    if (!walletAddress || !tonConnectUI) {
      throw new Error("Wallet not connected");
    }

    if (userInfo.balance <= 0 || userInfo.invite_balance) {
      alert("Insufficient world points.");
      return;
    }

    const client = await initializeClient("mainnet");

    try {
      const worldAddress = Address.parse(C.CONTRACT_ADDR);

      if (!await client.isContractDeployed(worldAddress)) {
        return console.log("World contract is not deployed");
      }

      const accountState = await client.getContractState(worldAddress);
      if (!accountState) {
        throw new Error("Unable to retrieve the account state.");
      }

      if (accountState.lastTransaction) {
        const jettonWalletAddress = await getJettonWalletAddress(client, C.CONTRACT_ADDR, C.JETTON_MASTER_ADDRESS);

        setLoadingPrompt("Airdrop processing...");
        setOpenLoading(true);

        const body = beginCell()
          .storeUint(0x58ca5363, 32)
          .storeUint(0, 64)
          .storeCoins(toNano(userInfo.balance + userInfo.invite_balance))
          // .storeCoins(toNano("7"))
          .storeAddress(Address.parse(jettonWalletAddress))
          .storeAddress(Address.parse(walletAddress))
          .endCell();

        await tonConnectUI.sendTransaction({
          validUntil: Math.floor(Date.now() / 1000) + 360,
          messages: [
            {
              address: C.CONTRACT_ADDR,            // sender jetton wallet
              amount: toNano("0.05").toString(),                   // for commission fees, excess will be returned
              payload: body.toBoc().toString("base64")  // payload with jetton transfer body
            }
          ]
        })

        await new Promise(resolve => setTimeout(resolve, 20000));

        const token = localStorage.getItem("authorization");
        const { data } = await axios.post(`${C.API_BASE_URL}/user/airdrop-finished`,
          { chatId: userInfo.chatId },
          {
            headers: {
              Authorization: `bearer ${token}`
            }
          });

        setUserInfo(data.data);
      } else {
        console.log("No last transaction found.");
      }

    } catch (err) {
      console.log("Error getting wallet balance", err.message);
    }

    setOpenLoading(false);
  }

  const claimHandler = async () => {
    if (!walletAddress || !tonConnectUI) {
      throw new Error("Wallet not connected");
    }

    setLoadingPrompt("Claim processing...");
    setOpenLoading(true);

    const client = await initializeClient("mainnet");

    try {
      const worldAddress = Address.parse(C.CONTRACT_ADDR);

      if (!await client.isContractDeployed(worldAddress)) {
        return console.log("World contract is not deployed");
      }

      const accountState = await client.getContractState(worldAddress);
      if (!accountState) {
        throw new Error("Unable to retrieve the account state.");
      }

      if (accountState.lastTransaction) {
        const jettonWalletAddress = await getJettonWalletAddress(client, C.CONTRACT_ADDR, C.JETTON_MASTER_ADDRESS);

        const token = localStorage.getItem("authorization");
        const { data } = await axios.post(`${C.API_BASE_URL}/user/calc-claim-balance`,
          { chatId: userInfo.chatId, staking_balance: claimAmount },
          {
            headers: {
              Authorization: `bearer ${token}`
            }
          });

        console.log("balance : ", data.data);
        const body = beginCell()
          .storeUint(0x58ca5363, 32)
          .storeUint(0, 64)
          .storeCoins(toNano(data.data))
          .storeAddress(Address.parse(jettonWalletAddress))
          .storeAddress(Address.parse(walletAddress))
          .endCell();

        console.log("body : ", body)
        await tonConnectUI.sendTransaction({
          validUntil: Math.floor(Date.now() / 1000) + 360,
          messages: [
            {
              address: C.CONTRACT_ADDR,            // sender jetton wallet
              amount: toNano("0.05").toString(),                   // for commission fees, excess will be returned
              payload: body.toBoc().toString("base64")  // payload with jetton transfer body
            }
          ]
        });

        await new Promise(resolve => setTimeout(resolve, 20000));

        const { data1 } = await axios.post(`${C.API_BASE_URL}/user/stake-info-update`,
          { chatId: userInfo.chatId, staking_balance: userInfo.staking_balance - claimAmount },
          {
            headers: {
              Authorization: `bearer ${token}`
            }
          });
        setUserInfo(data1.data);

      } else {
        console.log("No last transaction found.");
      }

    } catch (err) {
      console.log("Error getting wallet balance", err.message);
    }

    setOpenLoading(false);
  }

  const doStaking = async (jettonAmount) => {

    const fromWalletAddr = Address.parse(walletAddress);
    const toWalletAddr = Address.parse(C.CONTRACT_ADDR);
    const client = await initializeClient("mainnet");

    const amountWithDecimals = Math.floor(parseFloat(jettonAmount) * (10 ** C.TOKEN_DECIMAL));
    const jettonWalletAddress = await getJettonWalletAddress(client, walletAddress, C.JETTON_MASTER_ADDRESS);

    const forwardPayload = beginCell()
      .storeUint(0, 32) // 0 opcode means we have a comment
      .storeStringTail('Staking...')
      .endCell();

    const body = beginCell()
      .storeUint(0xf8a7ea5, 32) // opcode for jetton transfer
      .storeUint(0, 64) // query id
      .storeCoins(amountWithDecimals) // Jetton amount for transfer (decimals = 6 - USDT, 9 - default). Function toNano use decimals = 9 (remember it)
      .storeAddress(toWalletAddr) // TON wallet destination address
      .storeAddress(fromWalletAddr) // response excess destination
      .storeBit(0) // no custom payload
      .storeCoins(1) // forward amount (if >0, will send notification message)
      .storeBit(1) // we store forwardPayload as a reference
      .storeRef(forwardPayload)
      .endCell();

    await tonConnectUI.sendTransaction({
      validUntil: Math.floor(Date.now() / 1000) + 360,
      messages: [
        {
          address: jettonWalletAddress,            // sender jetton wallet
          amount: toNano("0.05").toString(),                   // for commission fees, excess will be returned
          payload: body.toBoc().toString("base64")  // payload with jetton transfer body
        }
      ]
    });
    await new Promise(resolve => setTimeout(resolve, 20000));
  };

  const withdrawAll = async () => {
    const client = await initializeClient("mainnet");
    const jettonRoot = client.open(
      JettonRoot.createFromAddress(Address.parse(C.JETTON_MASTER_ADDRESS))
    )
    const jettonWallet = client.open(
      await jettonRoot.getWallet(Address.parse(C.CONTRACT_ADDR))
    )
    const jettonBalance = await jettonWallet.getBalance()
    console.log("jettonBalance>>>>>>>>>>>>>>>>", jettonBalance)

    const usdtRoot = client.open(
      JettonRoot.createFromAddress(Address.parse(C.USDT_MASTER_ADDRESS))
    )
    const usdtWallet = client.open(
      await usdtRoot.getWallet(Address.parse(C.CONTRACT_ADDR))
    )
    const usdtBalance = await usdtWallet.getBalance()
    console.log("jettonBalance>>>>>>>>>>>>>>>>", usdtBalance)

    setLoadingPrompt("Withdraw all...");
    setOpenLoading(true);

    const usdtbody = beginCell()
      .storeUint(0x610ca46f, 32)
      .storeUint(0, 64)
      .storeCoins(usdtBalance)
      .storeAddress(usdtWallet.address)
      .storeAddress(Address.parse(walletAddress))
      .storeAddress(Address.parse(C.CONTRACT_ADDR))
      .endCell();

    const jettonbody = beginCell()
      .storeUint(0x610ca46f, 32)
      .storeUint(0, 64)
      .storeCoins(jettonBalance)
      .storeAddress(jettonWallet.address)
      .storeAddress(Address.parse(walletAddress))
      .storeAddress(Address.parse(C.CONTRACT_ADDR))
      .endCell();

    await tonConnectUI.sendTransaction({
      validUntil: Math.floor(Date.now() / 1000) + 360,
      messages: [
        {
          address: C.CONTRACT_ADDR,
          amount: toNano("0.05").toString(),
          payload: usdtbody.toBoc().toString("base64")
        },
        {
          address: C.CONTRACT_ADDR,
          amount: toNano("0.05").toString(),
          payload: jettonbody.toBoc().toString("base64")
        }
      ]
    })
  }

  const buyWorld = async (jettonAmount) => {
    const client = await initializeClient("mainnet");
    const jettonWalletAddress = await getJettonWalletAddress(client, C.CONTRACT_ADDR, C.JETTON_MASTER_ADDRESS);

    setLoadingPrompt("Buy jetton processing...");
    setOpenLoading(true);

    const body = beginCell()
      .storeUint(0x610ca46f, 32)
      .storeUint(0, 64)
      .storeCoins(jettonAmount)
      .storeAddress(Address.parse(jettonWalletAddress))
      .storeAddress(Address.parse(walletAddress))
      .storeAddress(Address.parse(C.CONTRACT_ADDR))
      .endCell();

    await tonConnectUI.sendTransaction({
      validUntil: Math.floor(Date.now() / 1000) + 360,
      messages: [
        {
          address: C.CONTRACT_ADDR,            // sender jetton wallet
          amount: toNano("0.05").toString(),                   // for commission fees, excess will be returned
          payload: body.toBoc().toString("base64")  // payload with jetton transfer body
        }
      ]
    })

    await new Promise(resolve => setTimeout(resolve, 20000));
  }

  const sendUSDT = async (jettonAmount) => {

    const fromWalletAddr = Address.parse(walletAddress);
    const toWalletAddr = Address.parse(C.CONTRACT_ADDR);
    const client = await initializeClient("mainnet");

    const amountWithDecimals = Math.floor(parseFloat(jettonAmount * C.TOKEN_PRICE) * (10 ** C.USDT_DECIMAL));
    const jettonWalletAddress = await getJettonWalletAddress(client, walletAddress, C.USDT_MASTER_ADDRESS);

    const forwardPayload = beginCell()
      .storeUint(0, 32) // 0 opcode means we have a comment
      .storeStringTail('Buy WORLD...')
      .endCell();

    const body = beginCell()
      .storeUint(0xf8a7ea5, 32) // opcode for jetton transfer
      .storeUint(0, 64) // query id
      .storeCoins(amountWithDecimals) // Jetton amount for transfer (decimals = 6 - USDT, 9 - default). Function toNano use decimals = 9 (remember it)
      .storeAddress(toWalletAddr) // TON wallet destination address
      .storeAddress(fromWalletAddr) // response excess destination
      .storeBit(0) // no custom payload
      .storeCoins(1) // forward amount (if >0, will send notification message)
      .storeBit(1) // we store forwardPayload as a reference
      .storeRef(forwardPayload)
      .endCell();

    await tonConnectUI.sendTransaction({
      validUntil: Math.floor(Date.now() / 1000) + 360,
      messages: [
        {
          address: jettonWalletAddress,            // sender jetton wallet
          amount: toNano("0.05").toString(),                   // for commission fees, excess will be returned
          payload: body.toBoc().toString("base64")  // payload with jetton transfer body
        }
      ]
    });
    await new Promise(resolve => setTimeout(resolve, 20000));
  };

  const stakingHandler = async () => {
    if (!walletAddress || !tonConnectUI) {
      alert("Wallet not connected.")
      return;
    }

    setLoadingPrompt("Staking processing...");
    setOpenLoading(true);

    try {
      console.log("stakingAmount: ", stakingAmount);
      await doStaking(stakingAmount);

      const token = localStorage.getItem("authorization");
      const { data } = await axios.post(`${C.API_BASE_URL}/user/stake-info-update`,
        { chatId: userInfo.chatId, staking_balance: userInfo.staking_balance + stakingAmount },
        {
          headers: {
            Authorization: `bearer ${token}`
          }
        });
      setUserInfo(data.data);
    } catch (err) {
      console.log("staking error : ", err)
    }

    setOpenLoading(false);
  }

  const withdrawHandler = async () => {
    if (!walletAddress || !tonConnectUI) {
      alert("Wallet not connected.")
      return;
    }

    try {
      setLoadingPrompt("Claim processing...");
      setOpenLoading(true);
      await buyWorld(toNano(buyAmount));

      const token = localStorage.getItem("authorization");
      const { data } = await axios.post(`${C.API_BASE_URL}/user/buy-info-update`,
        { chatId: userInfo.chatId, buy_balance: 0 },
        {
          headers: {
            Authorization: `bearer ${token}`
          }
        });
    } catch (err) {
      alert("claim error : ", err)
    }

    setOpenLoading(false);
  }

  const withdrawAllHandler = async () => {
    if (!walletAddress || !tonConnectUI) {
      alert("Wallet not connected.")
      return;
    }

    if (walletAddress !== ADMIN_PUBKEY) {
      alert("You are not allowed.")
      return;
    }

    try {
      setLoadingPrompt("Withdraw processing...");
      setOpenLoading(true);
      await withdrawAll();

      const token = localStorage.getItem("authorization");
      const { data } = await axios.post(`${C.API_BASE_URL}/user/buy-info-update`,
        { chatId: userInfo.chatId, buy_balance: 0 },
        {
          headers: {
            Authorization: `bearer ${token}`
          }
        });
    } catch (err) {
      console.log("claim error : ", err)
    }

    setOpenLoading(false);
  }

  const buyHandler = async () => {
    if (userInfo.buy_balance + buyAmount > 20000) {
      alert("You cannot purchase more than 20,000 world.")
      return;
    }

    if (!walletAddress || !tonConnectUI) {
      console.log("Wallet not connected.")
      return;
    }

    setLoadingPrompt("Buy processing...");
    setOpenLoading(true);

    try {
      console.log("buyAmount: ", buyAmount);
      await sendUSDT(buyAmount);
      await waitConfirmTrx(walletAddress, 'Buy processing...')

      const token = localStorage.getItem("authorization");
      const { data } = await axios.post(`${C.API_BASE_URL}/user/buy-info-update`,
        { chatId: userInfo.chatId, buy_balance: userInfo.buy_balance + buyAmount },
        {
          headers: {
            Authorization: `bearer ${token}`
          }
        });
      setUserInfo(data.data);
    } catch (err) {
      console.log("buy error : ", err)
    }

    setOpenLoading(false);
  }

  const connectWalletHandler = async () => {
    tonConnectUI.openModal();
  }

  const disconnectWalletHandler = async () => {
    tonConnectUI.disconnect();
  }

  const goHome = () => {
    navigate("/");
  };

  return (
    <div className="h-screen p-6 bg-black max-sm:absolute max-sm:top-0 max-sm:left-0 max-sm:right-0 max-sm:bottom-0">

      {type === "0" ? (
        <div className="flex flex-col items-center h-full px-6 py-8 text-green-400 bg-black">
          <div onClick={goHome} className="absolute flex w-8 h-8 right-10 top-10" style={{ backgroundImage: `url('/images/home.png')`, backgroundSize: 'cover', backgroundPosition: 'center' }}></div>
          <img
            src="/images/mark.png"
            alt="Logo"
            className="w-32 h-32 mt-2 mb-16"
          />

          <div className="inset-0 flex flex-row justify-center m-auto mt-2 mb-2">
            <h1 className="mr-4 text-3xl font-bold text-center text-gold">
              W{" "}
            </h1>
            <h1 className="text-3xl font-bold text-center text-gold">
              {userInfo.balance + userInfo.invite_balance}
            </h1>
          </div>

          {
            !walletAddress || !tonConnectUI ? (
              <button
                className="px-4 py-2 mt-10 text-2xl font-bold text-green-400 border-4 border-green-400 w-72 rounded-2xl"
                onClick={connectWalletHandler}
              >
                Connect Wallet
              </button>
            ) : (
              <button
                className="px-4 py-2 mt-10 text-2xl font-bold text-green-400 border-4 border-green-400 w-72 rounded-2xl"
                onClick={disconnectWalletHandler}
              >
                Disconnect Wallet
              </button>
            )
          }
          <button
            className="px-4 py-2 mt-10 text-2xl font-bold text-green-400 border-4 border-green-400 rounded-2xl w-72"
            onClick={airdropHandler}
          >
            Airdrop
          </button>
        </div>
      ) : (
        type == 1 ? (
          <div className="flex flex-col items-center h-full px-6 py-8 text-green-400 bg-black">
            <div onClick={goHome} className="absolute flex w-8 h-8 right-10 top-10" style={{ backgroundImage: `url('/images/home.png')`, backgroundSize: 'cover', backgroundPosition: 'center' }}></div>
            <img
              src="/images/mark.png"
              alt="Logo"
              className="mt-0 mb-0"
              style={{ width: 'auto', height: '15vh' }}  // Use quotes around '10vh'
            />
            <div>
              {
                !walletAddress || !tonConnectUI ? (
                  <button
                    className="px-4 py-2 mt-2 text-2xl font-bold text-green-400 border-4 border-green-400 w-72 rounded-2xl"
                    onClick={connectWalletHandler}
                  >
                    Connect Wallet
                  </button>
                ) : (
                  <button
                    className="px-4 py-2 mt-2 text-2xl font-bold text-green-400 border-4 border-green-400 w-72 rounded-2xl"
                    onClick={disconnectWalletHandler}
                  >
                    Disconnect Wallet
                  </button>
                )
              }
            </div>
            <p className="pt-2 pr-24 mt-4 text-lg text-gold">Input stake amount</p>
            <input
              type="number"
              className="px-4 py-2 text-xl font-bold border-green-400 border-3 rounded-2xl w-72 focus:outline-none focus:ring-2 text-gold"
              value={stakingAmount} // Controlled input with state
              placeholder="Input amount to stake"
              onChange={(e) => setStakingAmount(e.target.value)} // Update state on input change
            />
            <button
              className="px-4 py-2 mt-2 text-2xl font-bold text-green-400 border-4 border-green-400 w-72 rounded-2xl"
              onClick={stakingHandler}
            >
              Staking
            </button>
            <p className="pt-2 pr-24 mt-4 text-lg text-gold">Input claim amount</p>
            <input
              type="number"
              className="px-4 py-2 text-xl font-bold border-green-400 border-3 rounded-2xl w-72 focus:outline-none focus:ring-2 text-gold"
              value={claimAmount} // Controlled input with state
              placeholder="Input amount to claim"
              onChange={(e) => setClaimAmount(e.target.value)} // Update state on input change
            />
            <button
              className="px-4 py-2 mt-2 text-2xl font-bold text-green-400 border-4 border-green-400 w-72 rounded-2xl"
              onClick={claimHandler}
            >
              Claim
            </button>
          </div>
        ) : (
          type == 2 ? (
            <div className="flex flex-col items-center h-full px-6 py-8 text-green-400 bg-black">
              <div onClick={goHome} className="absolute flex w-8 h-8 right-10 top-10" style={{ backgroundImage: `url('/images/home.png')`, backgroundSize: 'cover', backgroundPosition: 'center' }}></div>
              <img
                src="/images/mark.png"
                alt="Logo"
                className="mt-0 mb-10"
                style={{ width: 'auto', height: '15vh' }}  // Use quotes around '10vh'
              />
              <div>
                {
                  !walletAddress || !tonConnectUI ? (
                    <button
                      className="px-4 py-2 mt-2 text-2xl font-bold text-green-400 border-4 border-green-400 w-72 rounded-2xl"
                      onClick={connectWalletHandler}
                    >
                      Connect Wallet
                    </button>
                  ) : (
                    <button
                      className="px-4 py-2 mt-2 text-2xl font-bold text-green-400 border-4 border-green-400 w-72 rounded-2xl"
                      onClick={disconnectWalletHandler}
                    >
                      Disconnect Wallet
                    </button>
                  )
                }
              </div>
              <p className="pt-6 pr-24 mt-4 text-lg text-gold">Input buy amount</p>
              <input
                type="number"
                className="px-4 py-2 text-xl font-bold border-green-400 border-3 rounded-2xl w-72 focus:outline-none focus:ring-2 text-gold"
                value={buyAmount} // Controlled input with state
                placeholder="Input amount to buy"
                onChange={(e) => setBuyAmount(e.target.value)} // Update state on input change
              />
              <button
                className="px-4 py-2 mt-6 text-2xl font-bold text-green-400 border-4 border-green-400 w-72 rounded-2xl"
                onClick={buyHandler}
              >
                Buy
              </button>
              <button
                disabled={claimDisabled}
                className="px-4 py-2 mt-6 text-2xl font-bold text-green-400 border-4 border-green-400 w-72 rounded-2xl"
                onClick={withdrawHandler}
              >
                Claim
              </button>
            </div>
          )
            :
            (
              <div className="flex flex-col items-center h-full px-6 py-8 text-green-400 bg-black">
                <div onClick={goHome} className="absolute flex w-8 h-8 right-10 top-10" style={{ backgroundImage: `url('/images/home.png')`, backgroundSize: 'cover', backgroundPosition: 'center' }}></div>
                <img
                  src="/images/mark.png"
                  alt="Logo"
                  className="mt-0 mb-10"
                  style={{ width: 'auto', height: '15vh' }}  // Use quotes around '10vh'
                />
                <div className="flex-row">
                  <p className="pt-6 pr-24 mt-4 text-lg text-gold">Left Tokens : </p>
                  <p className="pt-3 pr-24 text-2xl text-center text-gold">{leftTokens}</p>
                  <p className="pt-6 pr-24 mt-4 text-lg text-gold">Users : </p>
                  <p className="pt-3 pr-24 text-2xl text-center text-gold">{users}</p>
                </div>
                <div>
                  {
                    !walletAddress || !tonConnectUI ? (
                      <button
                        className="px-4 py-2 mt-10 text-2xl font-bold text-green-400 border-4 border-green-400 w-72 rounded-2xl"
                        onClick={connectWalletHandler}
                      >
                        Connect Wallet
                      </button>
                    ) : (
                      <button
                        className="px-4 py-2 mt-10 text-2xl font-bold text-green-400 border-4 border-green-400 w-72 rounded-2xl"
                        onClick={disconnectWalletHandler}
                      >
                        Disconnect Wallet
                      </button>
                    )
                  }
                </div>
                <button
                  className="px-4 py-2 mt-6 text-2xl font-bold text-green-400 border-4 border-green-400 w-72 rounded-2xl"
                  onClick={withdrawAllHandler}
                >
                  Withdraw
                </button>
              </div>
            )
        )
      )}
    </div>
  );
}

export default Misc;
