import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../layouts/Layout";
import { AppContext } from "../App";
import { API_BASE_URL } from "../config";
import axios from "axios";

// const flag = 948103062;
const USER_CHATID = 948103062;

export default function Home() {
  const { userInfo, setUserInfo } = useContext(AppContext);
  const navigate = useNavigate(); // Hook for navigation

  const showStakingDlg = () => {
    console.log("showStakingDlg");
    navigate("/wallet/1");
  };

  const moveBuyPage = () => {
    console.log("-------> moveBuyPage");
    navigate("/wallet/2");
  };

  const moveAdminPage = () => {
    navigate("/wallet/3");
  }

  const initLoading = async () => {
    try {
      const token = localStorage.getItem("authorization");
      const { data: userinfo } = await axios.get(`${API_BASE_URL}/user/info`, {
        headers: {
          Authorization: `bearer ${token}`
        }
      });
      console.log("[Userinfo]", userinfo);
      if (userinfo.state) setUserInfo(userinfo.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    console.log("============ init loading ...")
    initLoading();
  }, [])

  return (
    <div className="h-screen p-6 bg-black max-sm:absolute max-sm:top-0 max-sm:left-0 max-sm:right-0 max-sm:bottom-0">
      <div className="flex flex-col justify-between border-4 border-green-400 rounded-xl" style={{ height: "-webkit-fill-available" }}>
        <div className="flex flex-col items-center mb-4">
          <img
            src="/images/mark.png"
            alt="Logo"
            className="mt-6 mb-0"
            style={{ width: 'auto', height: '15vh' }}  // Use quotes around '10vh'
          />

        </div>

        <div className="flex flex-col justify-around gap-4">
          <div className="flex flex-row items-center pt-4 m-auto">
            <h1 className="mr-4 text-6xl text-gold"> W </h1>
            <div>
              <h2 className="text-lg text-gold"> YOUR WORLD </h2>
              <h2 className="text-3xl font-bold text-gold">
                {userInfo.balance + userInfo.invite_balance}
              </h2>
            </div>
          </div>
          <button
            className="px-2 py-2 ml-10 mr-10 text-xl font-bold text-green-400 border-4 border-green-400 rounded-xl"
            onClick={() =>
              (window.location.href = "https://whop.com/prosperous-world")
            }
          >
            ACADEMY
          </button>
          <button
            className="px-2 py-2 ml-10 mr-10 text-xl font-bold text-green-400 border-4 border-green-400 rounded-xl"
            onClick={() =>
              (window.location.href = " https://whop.com/prosperous-world")
            }
          >
            PRIME
          </button>
          <button
            className="px-2 py-2 ml-10 mr-10 text-xl font-bold text-green-400 border-4 border-green-400 rounded-xl"
            onClick={showStakingDlg}
          >
            EARN
          </button>
          <button
            className="px-2 py-2 ml-10 mr-10 text-xl font-bold text-green-400 border-4 border-green-400 rounded-xl"
            onClick={moveBuyPage}
          >
            BUY WORLD
          </button>
          {
            USER_CHATID == userInfo.chatId ?
              (<button
                className="px-2 py-2 ml-10 mr-10 text-xl font-bold text-green-400 border-4 border-green-400 rounded-xl"
                onClick={moveAdminPage}
              >
                ADMIN
              </button>)
              :
              (<div></div>)
          }
        </div>
        <div className="p-6">
          <Layout></Layout>
        </div>
      </div>
    </div>
  );
}
