import { TonClient, beginCell, Address, WalletContractV4 } from "@ton/ton";
import { sleep } from '@ton-community/assets-sdk/dist/utils'
import * as C from "../config"

const TON_END_POINT = 'https://toncenter.com/api/v2/jsonRPC';
const TON_API_KEY = 'e5479c4553ed5bcca752295a60b8b0af47ad9a51644699d0429035481a687bf0';

export async function getJettonWalletAddress(client, ownerAddress, jettonMasterAddress) {
  // if (ownerAddress === C.CONTRACT_ADDR)
  //   return "UQC7OYIkxIqWrezgeqw1YA0R9OfrrFG1kuDF_wuUqd14Xceb";
  // else {
  const result = await client.runMethod(
    Address.parse(jettonMasterAddress),
    "get_wallet_address",
    [{
      type: "slice",
      cell: beginCell()
        .storeAddress(Address.parse(ownerAddress))
        .endCell(),
    }]
  );
  return result.stack.readAddress().toString();
  // }
}

export async function initializeClient(network) {
  // const endpoint = await getHttpEndpoint({ network });
  // return new TonClient({ endpoint });

  return new TonClient({
    endpoint: TON_END_POINT,
    apiKey: TON_API_KEY
  });

}

export const waitConfirmTrx = async (address, trxName) => {
  const tonClient = await initializeClient("mainnet")
  const walletContract = tonClient.open(
    WalletContractV4.create({ publicKey: address, workchain: 0 })
  )
  const seqno = await walletContract.getSeqno()
  let currentSeqno = seqno

  while (currentSeqno == seqno) {
    console.log('waiting for ', trxName)
    await sleep(1500)
    currentSeqno = await walletContract.getSeqno()
  }

  console.log('transaction confirmed! : ', trxName)
}

export const roundDecimal = (numberStr, digits) => {
  const number = parseInt(numberStr);
  return number.toLocaleString("en-US", { maximumFractionDigits: digits });
};