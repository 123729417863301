export const API_BASE_URL = "https://miniworld.redlight.support/api";
// export const API_BASE_URL = "https://localhost:59997/api";
export const CHAT_ID = 6866688854;

export const TOKEN_DECIMAL = 9
export const USDT_DECIMAL = 6
export const TOKEN_PRICE = 0.1 //$
export const ADMIN_WALLET = "UQC7OYIkxIqWrezgeqw1YA0R9OfrrFG1kuDF_wuUqd14Xceb"
export const CONTRACT_ADDR = "EQCe2eXKD15iwa0q4NHvQa-c-IssKiOjO6T3muocJbn9K7hT"
export const RECEIVE_USDT_ADDR = "EQDenAzMRok8ec61-LOz_B8qhURnQvP8vxU6038eCkI48fqq"
export const USDT_MASTER_ADDRESS = "EQCxE6mUtQJKFnGfaROTKOt1lZbDiiX1kCixRv7Nw2Id_sDs"     // mainnet
export const JETTON_MASTER_ADDRESS = "EQAKU5uCVEfhlTLyMoMyYXeV-qavOJzoDt7TJfYAXefUZO2g"     // mainnet
// export const JETTON_MASTER_ADDRESS = "kQBuKAv-K57bwdpSPqvKfb0s_sST49LVAIchvmbJyeP10AG0"  // testnet